import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SConclusion1 from './strategic-conclusions/cs1.mdx';
import SConclusion2 from './strategic-conclusions/cs2.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "iii-conclusions---strategic"
    }}>{`III. Conclusions - Strategic`}</h1>
    <br />
    <SConclusion1 mdxType="SConclusion1" />
    <hr></hr>
    <br />
    <SConclusion2 mdxType="SConclusion2" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      